
import darkColors from "~/assets/dark.scss"
import responsive from "~/mixins/responsive"
import authMixins from "~/mixins/auth"
export default {
	name: "DefaultLayout",
	mixins: [responsive, authMixins],
	middleware: ["auth", "admin"],
	data() {
		return {
			drawer: true,
			fixed: false,
			searchMenu: null,
			bottomNavigationModel: 0,
			darkColors,
			right: true,
			rightDrawer: false,
			selectedItem: 1,
			isPermissionsLoaded: true,
			isPreferencesLoaded: true,
			setVisitorId: false,
			isInitiating: false,
		}
	},
	computed: {
		isAllowedToProceed() {
			return this.$store.getters.isInitialDataLoaded && this.isPermissionsLoaded && this.isPreferencesLoaded
		},
		filteredItem() {
			let items = []
			if (this.searchMenu) {
				this.items.forEach((item) => {
					if (item.children) {
						const children = item.children.filter((child) => {
							return child.title.toLowerCase().includes(this.searchMenu.toLowerCase())
						})
						if (children.length > 0) {
							children.forEach((child) => {
								child.subheader = item.title
								child.icon = item.icon
								child.highlightedTitle = this.highlightTitle(child.title)
								items.push(child)
							})
						}
					} else if (!item.children && item.title.toLowerCase().includes(this.searchMenu.toLowerCase())) {
						item.highlightedTitle = this.highlightTitle(item.title)
						items.push(item)
					}
				})

				// return this.items
				// 	.filter((item) => {
				// 		return (
				// 		item.title.toLowerCase().includes(this.searchMenu.toLowerCase()) ||
				// 		item.children?.filter((child) => {
				// 		return child.title.toLowerCase().includes(this.searchMenu.toLowerCase());
				// 		}).length > 0
				// 		);
				// 	})
				// 	.map((parent) => {
				// 		parent.active = true;
				// 		return parent;
				// 	});
			} else {
				items = this.items
			}
			// check permissions for items and its children
			return items
				.map((item) => {
					if (item.children) {
						item.children = item.children.filter((child) => {
							if (!child.permission) { return true }
							return this.$gates.hasPermission(child.permission)
						})
					}
					return item
				})
				.filter((item) => {
					if (item.children) {
						return item.children.length > 0
					} else if (item.permission) {
						return this.$gates.hasPermission(item.permission)
					} else {
						return true
					}
				})
		},
		items() {
			return [
				{
					icon: "mdi-view-dashboard",
					title: this.$t("menu.dashboard"),
					to: this.localePath({
						name: "dashboard",
					}),
				},
				{
					icon: "mdi-account-circle",
					title: this.$t("menu.orders"),
					children: [
						{
							title: this.$t("menu.orders"),
							to: this.localePath({
								name: "orders",
								params: {
									status: "received",
								},
							}),
						},
					],
				},
				// { title: "My Account", type: "subheader" },
				{
					icon: "mdi-card-account-details-star",
					title: this.$t("menu.inventory"),
					children: [
						{
							title: this.$t("menu.products"),
							to: this.localePath({
								name: "products",
							}),
						},
						{
							title: this.$t("menu.categories-and-filters"),
							to: this.localePath({
								name: "categories",
							}),
						},
						{
							title: this.$t("menu.product-group"),
							to: this.localePath({
								name: "groups",
							}),
						},
						{
							title: this.$t("menu.brands"),
							to: this.localePath({
								name: "brands",
							}),
						},
						{
							title: this.$t("menu.attributes"),
							to: this.localePath({
								name: "attributes",
							}),
						},
					],
				},
				{
					icon: "mdi-account-circle",
					title: this.$t("menu.suppliers"),
					children: [
						{
							title: this.$t("menu.suppliers"),
							to: this.localePath({
								name: "suppliers",
							}),
						},
					],
				},

				{
					icon: "mdi-star",
					title: this.$t("menu.ratings"),
					children: [
						{
							title: this.$t("menu.ratings"),
							to: this.localePath({
								name: "ratings",
							}),
						},
					],
				},
				{
					icon: "mdi-wrench",
					title: this.$t("menu.auctions"),
					children: [
						{
							title: this.$t("menu.auctions"),
							to: this.localePath({
								name: "auctions",
							}),
						},
					],
				},
				// {
				// 	icon: "mdi-message-text",
				// 	title: this.$t("menu.action-used-souq"),
				// 	children: [
				// 		{
				// 			title: this.$t("menu.used-categories"),
				// 			to: this.localePath({
				// 				name: "used-categories",
				// 			}),
				// 		},

				// 		{
				// 			title: this.$t("menu.used-products"),
				// 			to: this.localePath({
				// 				name: "used-products",
				// 			}),
				// 		},
				// 	],
				// },
				{
					icon: "mdi-message-text",
					title: this.$t("menu.shippings"),
					to: this.localePath({
						name: "shippings-carriers",
					}),
				},

				{
					icon: "mdi-message-text",
					title: this.$t("menu.system-configurations"),
					children: [
						{
							title: this.$t("menu.currencies"),
							to: this.localePath({
								name: "currencies",
							}),
						},

						{
							title: this.$t("menu.payments-methods"),
							to: this.localePath({
								name: "paymentsMethods",
							}),
						},
						{
							title: this.$t("menu.cities"),
							to: this.localePath({
								name: "cities",
							}),
						},
						{
							title: this.$t("menu.countries"),
							to: this.localePath({
								name: "countries",
							}),
						},
					],
				},
				{
					icon: "mdi-pencil",
					title: this.$t("menu.ai"),
					children: [
						{
							title: this.$t("menu.ai"),
							to: this.localePath({
								name: "ai",
							}),
						},
					],
				},

				{
					icon: "mdi-account-circle",
					title: this.$t("menu.users"),
					children: [
						{
							title: this.$t("menu.customers"),
							to: this.localePath({
								name: "customers",
							}),
						},
						{
							title: this.$t("menu.request-change-name"),
							to: this.localePath({
								name: "requestChangeName",
							}),
						},
						{
							title: this.$t("menu.deleted-users"),
							to: this.localePath({
								name: "deletedUsers",
							}),
						},
					],
				},

				{
					icon: "mdi-message-text",
					title: this.$t("menu.pages"),
					children: [
						{
							title: this.$t("menu.pages"),
							to: this.localePath({
								name: "pages",
							}),
						},
					],
				},
				{
					icon: "mdi-message-text",
					title: this.$t("menu.contact-us"),
					children: [
						{
							title: this.$t("menu.contact-us"),
							to: this.localePath({
								name: "contact-us",
							}),
						},
					],
				},
				{
					icon: "mdi-message-text",
					title: this.$t("menu.translations"),
					children: [
						{
							title: this.$t("menu.translations"),
							to: this.localePath({
								name: "translations",
							}),
						},

						{
							title: this.$t("menu.translation-tags"),
							to: this.localePath({
								name: "translationTags",
							}),
						},
					],
				},
			]
		},
		otherLocale() {
			return this.$i18n.locales.find(locale => locale.code !== this.$i18n.locale)
		},
		bottomItems() {
			return [
				{
					icon: "mdi-headset",
					title: this.$t("menu.support"),
					to: this.localePath({
						name: "support-tickets-list",
					}),
				},
			]
		},
	},

	beforeCreate() {
		//! TODO
		// this.$store.dispatch("authExtend/loadPermissions").then(() => {
		// 	this.isPermissionsLoaded = true;
		// });
		// this.$store.dispatch("authExtend/loadPreferences").then(() => {
		// 	this.isPreferencesLoaded = true;
		// });
	},
	created() {
		this.$vuetify.rtl = this.$i18n.localeProperties.dir === "rtl"
	},
	mounted() {
		window.addEventListener("keydown", (e) => {
			if (e.key === "s" && e.ctrlKey) {
				e.preventDefault()
				this.$refs.searchInput.focus()
			}

			if (e.key === "F8") {
				e.preventDefault()
				/* eslint-disable-next-line */
				debugger;
			}
		})
	},
	methods: {
		increaseLimit() {
			if (this.limit < this.countries.length) {
				this.limit += 12
			}
		},
		onMutate() {
			let height = 0
			const header = this.$refs.header
			if (header) {
				height = `${header.$el.offsetHeight}px`
			}
			document.querySelector(":root").style.setProperty("--headerHeight", height)
		},
		drawerHandler() {
			if (this.isDesktop) {
				this.storage.miniDrawer = !this.storage.miniDrawer
			} else {
				this.storage.miniDrawer = false
				this.drawer = !this.drawer
			}
		},
		switchTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark

			// this.$cookies.set("theme", this.$vuetify.theme.dark ? "dark" : "light", { path: "/", maxAge: 60 * 60 * 24 * 365 });+
			this.storage.theme = this.$vuetify.theme.dark ? "dark" : "light"
		},
		highlightTitle(item) {
			return item.replace(new RegExp(this.searchMenu, "gi"), match => `<b class="primary--text yellow">${match}</b>`)
		},
		goToSelectedItem() {
			if (this.selectedItem > -1) {
				this.$router.push(this.filteredItem[this.selectedItem].to)
			}
		},
		selectNextItem() {
			if (this.selectedItem < this.filteredItem.length - 1) {
				this.selectedItem++
			}
		},
		selectPreviousItem() {
			if (this.selectedItem > 0) {
				this.selectedItem--
			}
		},
		selectFirstItem(input) {
			if (this.filteredItem.length > 0 && input) {
				this.selectedItem = 0
			} else {
				this.selectedItem = -1
			}
		},

		logout() {
			this.$auth
				.logout()
				.then(() => {
					// check if this route has the middleware auth
					this.$router.push(this.localePath({ name: "index" }))
				})
		},
	},
}
